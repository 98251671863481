<template>
  <v-container
    class="fill-height"
    fluid>
    <v-row
      align="center"
      justify="center">
      <v-col
        :cols="12"
        class="text-center">
        Loading
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthProvider from '@/resources/AuthProvider'
import { mapActions } from 'vuex'
import { setAuthUser, clearAuth } from '@/assets/js/Authentication'

const AuthService = new AuthProvider()

export default {
  created () {
    clearAuth()
    this.checkPage()
  },
  methods: {
    ...mapActions({
      setSnackBar: 'Components/setSnackbar',
      initStore: 'Store/initStore'
    }),
    async login (token) {
      try {
        const { accessToken } = await AuthService.login(token)
        const auth = setAuthUser(accessToken)
        await this.initStore()
        if (auth) {
          this.setSnackBar({
            value: true,
            message: this.$t('snackbar.Login Success'),
            type: 'success'
          })
          this.$router.push({ name: 'Home' })
        } else {
          throw new Error()
        }
      } catch (error) {
        this.setSnackBar({
          value: true,
          message: this.$t('snackbar.Something went wrong Please reload the page'),
          type: 'error'
        })
        console.error('login', error)
      }
    },
    checkLogin () {
      const { query } = this.$route
      if (query && query.token_reference) {
        this.login(query.token_reference)
      } else {
        setTimeout(() => {
          const uri = `${window.location.origin}${this.$route.path}`
          window.location.replace(`${process.env.VUE_APP_LOGIN}?callback_uri=${encodeURI(uri)}`)
        }, 1000)
      }
    },
    checkPage () {
      if (this.$route.name === 'Logout') {
        this.$router.replace({ name: 'Login' })
        this.checkLogin()
      } else {
        this.checkLogin()
      }
    }
  }
}
</script>
