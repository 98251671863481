import HttpRequest from './HttpRequest'

class AuthProvider extends HttpRequest {
  login (token) {
    return this.post('/users/login', {
      tokenReference: token
    })
  }
}

export default AuthProvider
